<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-item`"  v-for="(item, index) in tabsList" :key="item.id">
      <el-dropdown placement="bottom" v-if="item.children.length">
        <div @click="changeTabs(item)" :class="`${prefixCls}-item-label ${tabActive === item.id?prefixCls+'-item-active':''}`">
          <el-button class="header-btn" :class="{ noLine: index === tabsList.length - 1 }" type="text" style="color:#000; font-size: 18px; font-weight: 400">{{item.label}}</el-button>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="value in item.children" :key="value.id">
            <div :class="`${prefixCls}-item-children`" @click="changeTabs(value)">{{value.label}}</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div @click="changeTabs(item)" v-else :class="`${prefixCls}-item-label ${tabActive === item.id?prefixCls+'-item-active':''}`">
        <el-button class="header-btn" type="text" style="color:#000; font-size: 18px; font-weight: 400;">{{item.label}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { tabsList } from './data'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      prefixCls: this.$prefix + '-layout-header-tabs',
      tabsList
    }
  },
  created () {

  },
  computed: {
    ...mapGetters(['tabActive'])
  },
  methods: {
    ...mapMutations(['setData']),
    changeTabs (item) {
      if (item.path === this.$route.path) {
        if (item.href) {
          document.querySelector(item.href).scrollIntoView({
            behavior: 'smooth'
          })
          this.setData({
            name: 'tabHref',
            value: item.href,
            store: true
          })
        }
        return false
      } else {
        if (item.path) {
          this.$router.push(item.path)
          if (item.href) {
            setTimeout(() => {
              this.setData({
                name: 'tabHref',
                value: item.href,
                store: true
              })
              document.querySelector(item.href).scrollIntoView({
                behavior: 'smooth'
              })
            }, 1000)
          } else {
            document.body.scrollIntoView()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
  $prefixCls: $namespace + -layout-header-tabs;
  .#{$prefixCls}{
    width: 60%;
    max-width: 800px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 40px;
    &-item{
      position: relative;
      border-color: #fff;
      &-label{
        font-size: 18px;
        cursor: pointer;
        white-space: nowrap;
        color: black;
        border: none;
        padding: 10px 0;
      }
      &-children {
        width: 100%;
        height: 100%;
        font-size: 16px;
      }
      &-active {
        border-color: #fff;
      }
      .header-btn {
        position: relative;
        //&:after {
        //  position: absolute;
        //  right: -15px;
        //  top: 12px;
        //  content: '/';
        //  display: block;
        //  font-size: 18px;
        //  font-weight: 400;
        //  color: #000;
        //}
        &.noLine {
          &:after {
            display: none;
          }
        }
      }
      //&-active:before{
      //  content: '';
      //  position: absolute;
      //  width: 100%;
      //  height: 2px;
      //  background-color: $primary-color;
      //  bottom: 0;
      //}
    }
  }
  ::v-deep .el-dropdown-menu {
    background-color: #85C1E9 !important;
    border: none;
    z-index: 1000;
  }
  ::v-deep .el-dropdown-menu__item:hover{
    background-color: #85C1E9;
  }
</style>
