export const tabsList = [
  {
    id: '1',
    label: '首页',
    path: '/home',
    active: true,
    children: []
  },
  {
    id: '2',
    label: '金鳌船艇',
    path: '/aochen',
    active: false,
    children: [
      { id: '7', label: '企业简介', path: '/aochen/intro', href: '#aochenIntro' },
      // { id: '8', label: '董事长寄语', path: '/aochen/chairman', href: '#aochenChairman' },
      { id: '9', label: '企业文化', path: '/aochen/culture', href: '#aochenCulture' },
      // { id: '10', label: '管理团队', path: '/aochen/team', href: '#aochenTeam' },
      { id: '11', label: '资质荣誉', path: '/aochen/honors', href: '#aochenHonors' },
      { id: '12', label: '社会责任', path: '/aochen/social', href: '#aochenSocial' }
      // { id: '13', label: '视频中心', path: '/aochen/video', href: '#aochenVideo' }
    ]
  },
  // {
  //   id: '19',
  //   label: '产品中心',
  //   path: '/core',
  //   active: false,
  //   children: [
  //     // { id: '20', label: '孕婴健康', path: '/core?searchType=11', href: '#productCore' },
  //     // { id: '201', label: '生殖健康', path: '/core?searchType=12', href: '#productCore' },
  //     // { id: '202', label: '胃肠道健康', path: '/core?searchType=13', href: '#productCore' },
  //     // { id: '203', label: '抗炎镇痛', path: '/core?searchType=14', href: '#productCore' },
  //     // { id: '204', label: '抗过敏', path: '/core?searchType=15', href: '#productCore' },
  //     // { id: '205', label: '其他', path: '/core?searchType=16', href: '#productCore' }
  //     // // { id: '21', label: '海洋大数据', path: '/core', href: '#aochenStrategy' },
  //     // { id: '22', label: '技术优势', path: '/core', href: '#coreSuperiority' }
  //   ]
  // },
  // {
  //   id: '3',
  //   label: '研发创新',
  //   path: '/press',
  //   active: false,
  //   children: [
  //     { id: '20', label: '研发概述', path: '/press/overview', href: '#aochenOverview' },
  //     { id: '21', label: '研发战略', path: '/press/strategy', href: '#aochenStrategy' },
  //     { id: '22', label: '研究成果', path: '/press/talents', href: '#aochenTalent' }
  //     // { id: '23', label: '研发专利', path: '/press/new', href: '#aochenPatent' }
  //   ]
  // },
  {
    id: '4',
    label: '新闻公告',
    path: '/cases',
    active: false,
    children: [
      { id: '14', label: '企业动态', path: '/cases/dynamics', href: '#aochenDynamics' },
      { id: '15', label: '行业资讯', path: '/cases/industry', href: '#aochenIndustry' },
      { id: '16', label: '通知公告', path: '/cases/info', href: '#aochenPatent' }
    ]
  },
  {
    id: '5',
    label: '党建工会',
    path: '/solutions',
    active: false,
    children: [
      { id: '17', label: '党建工作', path: '/solutions/work', href: '#aochenWork' },
      { id: '18', label: '工会团建', path: '/solutions/build', href: '#aochenBuild' }
      // { id: '19', label: '安全管理', path: '/solutions/safe', href: '#aochenSafe' }
    ]
  },
  {
    id: '6',
    label: '人力资源',
    path: '/manpower',
    active: false,
    children: [
      { id: '30', label: '人才理念', path: '/manpower/person', href: '#aochenTalent' },
      { id: '31', label: '招贤纳士', path: '/manpower/invite', href: '#aochenInvite' },
      { id: '32', label: '教育培训', path: '/manpower/edu', href: '#aochenEdu' }
    ]
  },
  {
    id: '7',
    label: '联系我们',
    path: '/contact',
    active: false,
    children: [
      { id: '72', label: '联系方式', path: '/contact', href: '#aochenWay', active: false, children: [] },
      { id: '73', label: '在线留言', path: '/contact', href: '#aochenBoard', active: false, children: [] }
    ]
  }
]
